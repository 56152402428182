<template>
  <div id="PacePool">
    <!-- pace pool menu ========================= -->
    <div class="pace-menu" ref="pace_menu">
      <div class="menu active" @click="scrollMeTo('new_version')">$PACE</div>
      <div class="menu" @click="scrollMeTo('staking')">STAKING</div>
      <div class="menu" @click="scrollMeTo('roadmap')">ROADMAP</div>
    </div>

    <div class="pace">
      <div class="pace-wrap">
        <div class="text-wrap">
          <div class="text" :class="{open: visible0}" @click.stop="showItem(0)">
            <div class="title">
              <v-icon v-if="visible0">mdi-minus</v-icon>
              <v-icon v-else>mdi-plus</v-icon>
              {{ $t("pacepool.sliderTitle01") }}
            </div>
            <div class="description">{{ $t("pacepool.sliderContent01") }}</div>
          </div>

          <div class="text" :class="{open: visible1}" @click.stop="showItem(1)">
            <div class="title">
              <v-icon v-if="visible1">mdi-minus</v-icon>
              <v-icon v-else>mdi-plus</v-icon>
              {{ $t("pacepool.sliderTitle02") }}
            </div>
            <div class="description">{{ $t("pacepool.sliderContent02") }}</div>
          </div>

          <div class="text" :class="{open: visible2}" @click.stop="showItem(2)">
            <div class="title">
              <v-icon v-if="visible2">mdi-minus</v-icon>
              <v-icon v-else>mdi-plus</v-icon>
              {{ $t("pacepool.sliderTitle03") }}
            </div>
            <div class="description">{{ $t("pacepool.sliderContent03") }}</div>
          </div>

          <div class="text" :class="{open: visible3}" @click.stop="showItem(3)">
            <div class="title">
              <v-icon v-if="visible3">mdi-minus</v-icon>
              <v-icon v-else>mdi-plus</v-icon>
              {{ $t("pacepool.sliderTitle04") }}
            </div>
            <div class="description">{{ $t("pacepool.sliderContent04") }}</div>
          </div>

          <div class="text" :class="{open: visible4}" @click.stop="showItem(4)">
            <div class="title">
              <v-icon v-if="visible4">mdi-minus</v-icon>
              <v-icon v-else>mdi-plus</v-icon>
              {{ $t("pacepool.sliderTitle05") }}
            </div>
            <div class="description">{{ $t("pacepool.sliderContent05") }}</div>
          </div>
        </div>
      </div>

      <img class="swirl" src="/img/threespace/about/swirl.png"/>
<!--      <div class="parent">-->
<!--        <template v-for="i in 32">-->
<!--          <div class="circle" :key="i"-->
<!--               :style="{width:getWidth(i), height: getHeight(i), transform: getTransform(i)}"-->
<!--          />-->
<!--        </template>-->
<!--      </div>-->
      <div class="pace-text">$PACE 2.0</div>
    </div>
    <div class="staking" ref="staking">
      <div class="staking-wrap">
        <div class="text-wrap">
          <div class="title">$PACE STAKING</div>
          <div class="description">{{ $t("pacepool.stakingInfo") }}</div>
          <b-button @click="onClickStakingBtn">
            GO TO POOL
          </b-button>
        </div>
      </div>
      <div class="staking-img">
        <img src="/img/threespace/about/staking-scheme.png"/>
      </div>
      <div class="staking-img mobile">
        <img src="/img/threespace/about/staking-scheme-mobile.png" />
      </div>
    </div>

    <div class="roadmap-section"  ref="roadmap">
      <div class="title">ROADMAP</div>
      <div class="roadmap-wrap">
        <template v-for="i in 5">
          <div class="roadmap-item" :key="i">
            <div class="line"/>
            <div class="date">{{ $t(`pacepool.roadmapTitle0${i}`) }}</div>
            <div class="description" v-html="$t(`pacepool.roadmapContent0${i}`)"/>
          </div>
        </template>
      </div>
    </div>


    <!-- pace pool top btn ========================= -->
    <aside
      id="PaceTopBtn"
      :class="{ active: isMobile ? scrollPosition > 400 : scrollPosition > 1000 }"
      @click="onClickTopBtn"
    >
      TOP
    </aside>
  </div>
</template>

<script>
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';

export default {
  name: "PacePool",
  metaInfo() {
    return {
      title: this.$t("meta.artPool.title") + " | 3space Art",
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "description", name: "description", content: this.$t("meta.artPool.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.artPool.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ]
    }
  },
  components: {
    Slick
  },
  data: function () {
    return {
      slickOptions: {
        dots: true,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        speed: 500,
      },
      mobileSlickOptions: {
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        speed: 500,
      },
      scrollPosition: 0,
      visible0:true,
      visible1:false,
      visible2:false,
      visible3:false,
      visible4:false
    }
  },
  computed: {
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    _slickOptions() {
      if (this.isMobile) {
        return this.mobileSlickOptions
      } else {
        return this.slickOptions
      }
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      this.scrollPosition = window.scrollY;
    },
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      element.scrollIntoView({ behavior: 'smooth' });
    },
    onClickTopBtn() {
      this.$refs["pace_menu"].scrollIntoView({ behavior: 'smooth' });
    },
    onClickStakingBtn() {
      this.$router.push("/art-pool/pace");
    },
    showItem(i) {
      for (let j = 0; j < 5;  j++) {
        const text = `visible${j}`
        if(i === j) {
          this[text] = !this[text]
        } else {
          this[text] = false
        }
      }
    },
    getWidth(i) {
      const pi = 3.141592;
      const squares = 32;
      const angle = pi / (squares - i);
      const ratio = 1 / (Math.sin(angle) + Math.cos(angle));

      return (Math.pow(ratio, i) * 50) + "vmin";
    },
    getHeight(i) {
      const pi = 3.141592;
      const squares = 32;
      const angle = pi / (squares - i);
      const ratio = 1 / (Math.sin(angle) + Math.cos(angle));

      return (Math.pow(ratio, i) * 100) + "vmin";
    },
    getTransform(i) {
      const pi = 3.141592;
      const squares = 32;
      const angle = pi / (squares - i);

      return `translate(-50%, -50%) rotate(70deg)`
    }
  },
  filters: {}
}
</script>
